.newsContainer{
    width: 80%;
    height: 400px;
    margin: 50px auto;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-bottom:20px ;
    gap: 20px;
    overflow: hidden;
  
}
.newsInnerContainer{
    display: flex;
    height: 400px;
    width: 80%;
    margin-bottom: 20px;
    box-shadow: 5px 5px 10px 10px rgba(236, 235, 235, 0.364);
    border-radius: 10px;
    position: absolute;

    opacity: 0;
    transition-duration: 1s ease;
    background-color: transparent;
}
.newsInnerContainer:hover{
    background-color: rgba(0, 157, 255, 0.315);
}
.left{
    flex:1 ;
    padding: 10px;
}
.left h1{
    font-size: 30px;
    font-weight: 700;
}
.left p{
    overflow: hidden;
}
.right{
    flex:1 ;
}
.right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.slide {
    opacity: 0;
    transition-duration: 1s ease;
    background-color: transparent;
  }
  .activate {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    background-color: transparent;

  }