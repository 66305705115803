.profileDetailsContainer{
    display: flex;
   gap: 20px;
   

}

.profileLeft{
    flex: 1;
}

.profileRight{
    flex: 2;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(0, 170, 255, 0.122);
}

.popContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 99999;
  background-color: black;
  /* background-color: skyblue; */
  text-align: center;
 padding: 20px;
 max-width: 250px;
  width: max-content;
  box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544);
}
.popText{
  color: white;
  font-weight: bold;
  
}
.innerPopContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.popButton{
  background-color: rgb(58, 135, 224);
  /* background-color: rgb(58, 135, 224); */
  padding:7px 15px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
}

.popClose{
  color: rgb(58, 135, 224);
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btnn {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width:220.52px;
  min-height: 56px;
  font-family: calibri;
  font-size: 1.2em;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: 2px solid rgb(58, 135, 224);
  color: rgb(58, 135, 224);
  background-color : transparent;
  overflow: hidden;
  margin: 20px;
}

i {
  font-size: 1em;
  padding: 0 10px;
  transition: all 0.5s linear;
}

  .btnn:hover i {
      transform: translateX(10px);
  }

  .btnn::before, .btnn::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background-color: rgb(58, 135, 224);
  z-index: -1;
  transition: all 400ms ease-in;
  }

  .btnn:hover {
      color: #fff;
  }

  .btnn::before {
      opacity: 0.3;
  }

  .btnn::after {
      transition-delay: 0.3s;
  }

  .btnn:hover::before, .btnn:hover::after {
      top: 0;
  }





@media screen and (max-width:960px) {
    .profileDetailsContainer{
        display: flex;
        flex-direction: column;
    }
}
