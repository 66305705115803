.walletsInnerContainer{
    width: 80%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;

}

.eachWallet{
    width: 280px;
    height: 180px;
    box-shadow: 5px 5px 15px 5px rgba(243, 242, 242, 0.293);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgba(67, 174, 204, 0.106);
    position: relative;
}

.mainWallet{
    width: 350px;
    height: 250px;
    min-width: 280px;
    min-height: 180px;
    box-shadow: 5px 5px 15px 5px rgba(243, 242, 242, 0.293);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgba(67, 145, 204, 0.319);
    position: relative;
}
.fund {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:220.52px;
    min-height: 56px;
    font-family: calibri;
    font-size: 1.2em;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    border: 2px solid rgb(58, 135, 224);
    color: rgb(58, 135, 224);
    background-color : transparent;
    overflow: hidden;
  }
  
  i {
    font-size: 1em;
    padding: 0 10px;
    transition: all 0.5s linear;
  }
  
    .fund:hover i {
        transform: translateX(10px);
    }
  
    .fund::before, .fund::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgb(58, 135, 224);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .fund:hover {
        color: #fff;
    }
  
    .fund::before {
        opacity: 0.3;
    }
  
    .fund::after {
        transition-delay: 0.3s;
    }
  
    .fund:hover::before, .fund:hover::after {
        top: 0;
    }
  