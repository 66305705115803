.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 99999;
    background-color: skyblue;
    text-align: center;
   
    width: max-content;
}
.text{
    color: white;
    font-weight: bold;
    padding: 20px;
}
.innerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.button{
    background-color: rgb(18, 80, 212);
    padding: 10px;
    border-radius: 5px;
}