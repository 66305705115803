
.loaderContainer{
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(11, 12, 11, 0.645); */
    background-color: rgba(250, 250, 250, 0.857);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 222;
    gap: 20px;
}


.loaderInnerContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 80vw;
    height: 80vh;
    z-index: 522;
    background-color: transparent;
}



.loaderImage{
    width: 120px;
    height: max-content;
    object-fit: contain;
    position: relative;
    animation-name: bounce;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; 
    /* animation-duration: 1s;  */
    animation: rotate 2s linear infinite;
    animation: 5s slide-right ease-in-out;
}


@keyframes rotate {
    from {
      transform: rotate(0deg);
      /* transform: translateY(-30px); */
    }
    to {
      transform: rotate(360deg);
      /* transform: translateY(-15px); */
    }
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 100%;
    }
  }
