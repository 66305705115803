
.topTagContainer{
    overflow:hidden;
     /* background-color:rgba(15, 149, 193, 0.469); */
     position: relative;
}
.topTagContainer:before,
.topTagContainer:after
{
    content: "";
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    z-index: 2;
}

.topTagContainer:hover .innerContainer{
    animation-play-state: paused;
}
.innerContainer{
   
     /* height:40px; */
     width: 100%;
      z-index:100;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
     white-space: nowrap;
    animation: 20s slide infinite linear ;

    }

   
    @keyframes slide {
        from{
            transform: translateX(0);
        }
        to{
            transform: translateX(calc(-250px * 15));
        }
    }
    .slide{
        width: 250px;
        height: 120px;
    }

    .image{
        width: 250px;
        min-width: 250px;
        height: 120px;
        object-fit: cover;
        overflow: hidden;
    }