
.topTagContainer{
    overflow:hidden;
     background-color:rgba(15, 149, 193, 0.469);
     position: relative;
}
.topTagContainer:before,
.topTagContainer:after
{
    content: "";
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    z-index: 2;
}
/* .topTagContainer:before{
    left: 0;
    background: linear-gradient(to left, rgba(255,255,255,0), rgba(255, 255, 255, 0.803));

}
.topTagContainer:after{
   right: 0; 
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255, 255, 255, 0.795));
} */
.topTagContainer:hover .innerContainer{
    animation-play-state: paused;
}
.innerContainer{
   
     /* height:40px; */
      z-index:100;
      display: flex;
      align-items: center;
     white-space: nowrap;
    animation: 50s slide infinite linear ;
    }

   
    @keyframes slide {
        from{
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }