
.btn {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:220.52px;
    min-height: 56px;
    font-family: calibri;
    font-size: 1.2em;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    border: 2px solid rgb(58, 135, 224);
    color: rgb(58, 135, 224);
    background-color : transparent;
    overflow: hidden;
  }
  
  i {
    font-size: 1em;
    padding: 0 10px;
    transition: all 0.5s linear;
  }
  
    .btn:hover i {
        transform: translateX(10px);
    }
  
    .btn::before, .btn::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgb(58, 135, 224);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .btn:hover {
        color: #fff;
    }
  
    .btn::before {
        opacity: 0.3;
    }
  
    .btn::after {
        transition-delay: 0.3s;
    }
  
    .btn:hover::before, .btn:hover::after {
        top: 0;
    }
  