.footerMiddleContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 80%;
    padding: 10px 40px;
    margin: auto;
}

.footerMiddleContainer img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    background-color: white;
}
.footerText{
    width: 100%;
    font-size: 18px;
    text-align: center;
}
.footerImage{
  
    padding: 5px;
    width: 150px;
    height: 150px;
}

@media screen and (max-width:960px) {
    .footerMiddleContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 80%;
        padding: 10px;
        margin: auto;
    }
    

}