.aboutContainer{
    width: 80%;
    margin: auto;
}
.aboutTop{
    display: flex;
    /* align-items: center; */
    gap: 20px;
    margin: 50px auto;
}
.aboutTop img{
    width: 400px;
    height: 500px;
    object-fit: cover;
}
.aboutTop li{
    margin-top: 15px;
    list-style-type:disc;
    margin-left: 20px;
}
.aboutTop li span{
    font-weight: bold;
}
.aboutMiddleInner{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 20px;
    background-color: rgb(24, 22, 22);
    color: white;
    margin: 50px 20px;
    padding: 20px;
}
.different{
    background-color: white;
    color: black;
    box-shadow: 5px 5px 15px 10px rgba(211, 211, 211, 0.346);
}
.aboutMiddleInner h2{
    flex: 1;
    font-size: 30px;
    font-weight: bold;
}
.aboutMiddleInner p{
    flex: 2;
    font-size: 20px;
}
.babyRobotContainer{
    display: flex;
    gap: 20px;
    margin: 100px auto;
    background-color: rgba(0, 140, 255, 0.212);
    padding: 20px;
}
.babyRobotContainer img{
    flex: 1;
    width: 550px;
    height: 100%;
    object-fit: cover;
}
.babyRobotContainer p{
    flex: 1;
   
}

.esgEachContainer{
    display: flex;
    gap: 20px;
    margin: 100px 50px;
}
    .esgLeft{
    flex: 1;
    }
    .esgRight{
        flex: 1;
    }

@media screen and (max-width:1200px) {
    .aboutTop{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
      
    }
    .babyRobotContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }
    .aboutTop img{
      object-fit: contain;
      
    }
    .esgEachContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width:800px) {
    .aboutMiddleInner{
        display: flex;
        flex-direction: column;
     
    }
}