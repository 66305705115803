.chooseTransferColumnContainer{
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 50px auto;
    flex-wrap: wrap;
}
.chooseTransferColumnContainer .left{

 background-color: rgb(15, 136, 228);
 padding: 20px;
 color: white;
max-width: 280px;
position: relative;
overflow: hidden;
transition: transform 2s ease-in-out;
}
.chooseTransferColumnContainer .left:hover{
   transform: scale(1.2);
    /* color: black; */
 
   }
   /* .left::before, .left::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgba(58, 135, 224, 0.499);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .left:hover {
        color: #fff;
    }
  
    .left::before {
        opacity: 0.3;
    }
  
    .left::after {
        transition-delay: 0.3s;
    }
  
    .left:hover::before, .left:hover::after {
        top: 0;
    } */
  
.left h1{
    font-size: 20px;
    font-weight: bold;
}
.left p{
    font-size: 12px;
    text-align: center;
}
.left .arrow{
    width: 100%;

    margin-top: 20px;
    animation: move 2s infinite ease-in-out;
}
@keyframes move {
    from{
        transform: translateX(0%);
    }
    to{
        transform: translateX(10%);
    }
}
.chooseTransferColumnContainer .right{
 font-size:18px;
 background-color: rgba(15, 136, 228, 0.436);
 padding: 20px;
 max-width: 280px;
 position: relative;
 overflow: hidden;
}

/* .chooseTransferColumnContainer .right:hover{
    background-color: rgba(15, 136, 228, 0.882);
    color: white;
   }


   */
    .right::before, .right::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgb(58, 135, 224);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .right:hover {
        color: #fff;
    }
  
    .right::before {
        opacity: 0.3;
    }
  
    .right::after {
        transition-delay: 0.3s;
    }
  
    .right:hover::before, .right:hover::after {
        top: 0;
    }
  
.right h1{
    font-size: 18px;
    font-weight: bold;
    
}
.right p{
    font-size: 12px;
    text-align: center;
}
.right .arrow{
    width: 100%;
    
    margin-top: 20px;
    animation: move 2s infinite ease-in-out;
}