.active{
    border-bottom: 3px solid rgba(0, 132, 255, 0.799);
    background-color: transparent;
    /* color: red; */
    /* background-color: red; */
}

.showContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    /* box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544); */
  }
  .showText{
    color: white;
    font-weight: bold;
    /* width: 400px; */
    
  }
  .innerShowContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
 
    background-color: rgb(0, 0, 0);
    text-align: center;
   padding: 20px;
    gap: 10px;
  }
  .showButton{
    background-color: rgb(58, 135, 224);
    /* background-color: rgb(58, 135, 224); */
    padding:7px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
  }
  
  .showClose{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }