.testimonial-container{
    width: 100%;
    /* background: transparent; */
    margin: 20px auto;
    padding: 50px 20px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: none;
   
}
.testimonial-container h3{
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
   
}
.next-prev{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    inset: 0;
    padding: 20px;
    cursor: pointer;
    background: transparent;
}
.test-button{
    cursor: pointer;
   z-index: 10;
}
.test-button:hover{
    background: rgba(128, 128, 128, 0.363);
    /* padding: 10px; */
    width: 30px;
    height: 30px;
}
.testimonial-container p{
    font-size: 25px;
    max-width: 70%;
    margin: auto;
    font-weight: 800;
}
.testimonial-container strong{
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 40px;
}
.slide {
    opacity: 0;
    transition-duration: 1s ease;
    background-color: transparent;
  }
  .activate {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    background-color: transparent;

  }
  
  /* .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    background-color: transparent;
  } */
/* .test-inner-container{
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
} */
@media screen and (max-width: 960px) {
    .testimonial-container p{
        font-size: 18px;
        max-width: 80%;
    }
}