
.downlineTransferContainer{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .downlineTransferContainer .left{
    flex: 1;
  }
  .downlineTransferContainer .left img{
    height: 500px;
    width: 90%;
    object-fit: contain;
    transform: rotate(180deg);
  }
  .downlineTransferContainer .right{
    flex: 1;
    border: 2px solid skyblue;
    padding: 10px;
  }
  .loginButton{
    background-color: rgb(58, 135, 224);
    padding: 10px;
    color: white;
    font-weight: 600;
    width: 100%;
}


.popContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 99999;
  background-color: black;
  /* background-color: skyblue; */
  text-align: center;
 padding: 20px;
 max-width: 250px;
  width: max-content;
  box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544);
}
.popText{
  color: white;
  font-weight: bold;
  
}
.innerPopContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.popButton{
  background-color: rgb(58, 135, 224);
  /* background-color: rgb(58, 135, 224); */
  padding:7px 15px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
}

.popClose{
  color: rgb(58, 135, 224);
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


  @media screen and (max-width:1100px) {
    .downlineTransferContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .downlineTransferContainer .left img{
      height: 300px;
      object-fit: cover;
    }
  }