.walletSlide-container{
    width: 320px;
    height: 200px;
    box-shadow: 5px 5px 15px 5px rgba(243, 242, 242, 0.293);
    background-color: rgba(67, 174, 204, 0.106);
    /* background: transparent; */
    margin: 20px auto;
    padding: 50px 20px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: none;
   
}
.walletSlide-container h3{
    padding: 10px;
    font-size: 18px;
    font-weight: 800;
    background-color: white;
    line-height: 20px;
    letter-spacing: 3px;

   
}
.next-prev{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    inset: 0;
    padding: 20px;
    cursor: pointer;
    background: transparent;
}
.test-button{
    cursor: pointer;
   z-index: 10;
}
.test-button:hover{
    background: rgba(128, 128, 128, 0.363);
    /* padding: 10px; */
    width: 30px;
    height: 30px;
}
.walletSlide-container p{
    font-size: 18px;
    max-width: 70%;
    margin: auto;
    font-weight: 800;
    color: rgb(29, 114, 200);
}
.walletSlide-container strong{
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 40px;
}
.walletSlide {
    opacity: 0;
    transition-duration: 1s ease;
    background-color: transparent;
  }
  .activate {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    background-color: transparent;

  }
  
  /* .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    background-color: transparent;
  } */
/* .test-inner-container{
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
} */
@media screen and (max-width: 960px) {
    .walletSlide-container p{
        font-size: 18px;
        max-width: 80%;
    }
}