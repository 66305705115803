.nav-container{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5px 5px 15px 5px rgba(211, 211, 211, 0.459);
    position:fixed !important;
    background-color: rgba(154, 192, 223, 0.226);
    /* filter: blur(10px); */
    z-index: 22;
    backdrop-filter: blur(10px);
    padding-left: 40px;
    padding-right: 40px;

  
}
.fixed{
    position: fixed; /* Fixed position when scrolling */
    top: 0;
}
.nav{
    color:rgb(58, 135, 224);
     font-weight:bold;
}
.nav:hover{
    border-bottom: 3px solid rgba(0, 132, 255, 1);
}
.nav-inner{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgba(154, 192, 223, 0.226);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 100px;
    padding: 20px;
    right: 100px;
    align-items: center;
  
}
.navIcon{
    color:rgb(58, 135, 224);
    font-weight:bold;
    margin-left: -15px;
    cursor: pointer;
    /* margin-bottom: -5px; */
    /* font-size: 18px; */
}

.active{
    border-bottom: 3px solid rgba(0, 132, 255, 0.799);
    /* color: red; */
    /* background-color: red; */
}
.navItems-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-right: 50px;
}



.navItems-inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
}

.menu-bar{
    display: none;
}




.rotate{
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
      transform: rotate(0deg);
      /* transform: translateY(-30px); */
    }
    to {
      transform: rotate(360deg);
      /* transform: translateY(-15px); */
    }
  }

@media screen  and (max-width:1200px) {
    .menu-bar{
        display: flex !important;
        font-size: 30px;
        margin-top: -8px;
        padding-right: 60px;
        color: rgb(28, 85, 176) ;
        z-index: 199900;
        position: absolute;
        cursor: pointer;
        animation: 1s ease-out 0s 1 slideInFromLeft;
        
      

    }
    .navItems-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        backdrop-filter: blur(10px);
        background: rgba(250, 250, 250, 0.973);
        width: 100%;
        height: 1500px;
        gap: 0px;
        padding: 20px 0;
        z-index: 20;
        position: absolute;
        left: -100%;
        backdrop-filter: blur(10px);
    }

    @keyframes slideInFromLeft {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0);
        }
      }
    
    .navItems-container.active{
        position: absolute;
            left: 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: rgba(213, 221, 226, 0.82);
            width: 100%;
            height: 1500px;
            gap: 10px;
            padding: 20px 0;
            margin-top: 300px;
            backdrop-filter: blur(10px);
            border-radius: 10px; /* Optional: Add border-radius for a rounded glass effect */
            border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: Add a border for a glass border effect */
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for depth */
                }
   
    .hovered-items{
        /* position: absolute; */
        top: 56%;
        padding-left: -100px;
        padding: 10px;
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 100000;
        background-color: transparent;
        
        
    }
    .hovered-items a{
        margin-left: 50px;
        margin-top: -20px;
        
    }
    .nav-inner{
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: rgba(154, 192, 223, 0.507);
        backdrop-filter: blur(10px);
        position: relative;
        top: 0px;
        padding: 20px;
        right: 0px;
      
    }
    .navIcon{
        margin-top: 0px;
    }
   
}

/* @media screen and (max-width: 288px) {

    .logo-container img{
        width: 200px;
        height: 80px;
        object-fit: contain;
        margin-left: -10px;
    }
    }

    @media screen and (max-width: 208px) {

        .logo-container img{
            width: 120px;
            height: 80px;
            object-fit: contain;
            margin-left: 5px;
        }
        } */