.onContainer{
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    gap: 50px;
    min-height: 100vh;
    background-image: linear-gradient(to bottom right, rgba(0, 140, 255, 0.239), rgba(255, 255, 255, 0.783));
    padding-bottom: 200px;
}
.imageHomeContainer{
    height: 400px;
    width: 300px;
    animation: slidein 1.5s ease-in-out;
}
.robotHomeImageOne{
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    z-index: 101;
    overflow: hidden;
    transition: all 500ms ease-in;
   
    animation: Shake 0.5s linear infinite; 
    
   
} 
/* .homeTextContainer{
    animation: slidein 1.5s ease-in-out;
} */
.robotImageOne:hover{
    animation: Shake 0.5s linear infinite; 
}
.tapText {
    color: black;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 20px;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    /* border-right: .15em solid black;  */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: -100px auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 2.0s steps(15, end),
      blink-caret .5s step-end infinite;
    
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 20% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  @keyframes Shake { 
    0% { 
        transform: rotate(5deg); 
    } 
  
    25% { 
        transform: rotate(-6deg); 
    } 
  
    50% { 
        transform: rotate(5deg); 
    } 
  
    75% { 
        transform: rotate(-6deg); 
    } 
  
    100% { 
        transform: rotate(5deg); 
    } 
}
@keyframes slidein {
    from {
        margin-left: -100%;
      }
      to {
        margin-left: 0%;
      }
  }


@media screen and (max-width:960px) {
    .robotImageOne{
      
        height: 200px;
        width: 100px;
       object-fit: contain;
        
        
       
    } 
}