/* .emailText{
    font-weight: 600;
    font-size: 20px;
} */
.transferContainer{
    width:100%;
     margin:auto;
}
.loginButton{
    background-color: rgb(58, 135, 224);
    padding: 10px;
    color: white;
    font-weight: 600;
    width: 100%;
}

/* DEMO-SPECIFIC STYLES */
.emailText {
    color: white;
    font-weight: 600;
    font-size: 20px;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    /* border-right: .15em solid black;  */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 80% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  .modal{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.566);
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 50px;
  }
  .close{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    cursor: pointer;
  }

  .loginImage{
    animation: Shake 0.5s linear infinite; 
  }

  .btn {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:220.52px;
    min-height: 56px;
    font-family: calibri;
    font-size: 1.2em;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    border: 2px solid rgb(58, 135, 224);
    color: white;
    background-color : transparent;
    overflow: hidden;
  }
  
  i {
    font-size: 1em;
    padding: 0 10px;
    transition: all 0.5s linear;
  }
  
    .btn:hover i {
        transform: translateX(10px);
    }
  
    .btn::before, .btn::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgb(58, 135, 224);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .btn:hover {
        color: #fff;
    }
  
    .btn::before {
        opacity: 0.3;
    }
  
    .btn::after {
        transition-delay: 0.3s;
    }
  
    .btn:hover::before, .btn:hover::after {
        top: 0;
    }
  
  

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  @keyframes Shake { 
    0% { 
        transform: rotate(3deg); 
    } 
  
    25% { 
        transform: rotate(-3deg); 
    } 
  
    50% { 
        transform: rotate(3deg); 
    } 
  
    75% { 
        transform: rotate(-3deg); 
    } 
  
    100% { 
        transform: rotate(3deg); 
    } 
}

.transferImagesContainer {
  position: relative; /* Ensure the container can contain absolute-positioned elements */
  width: 100%; /* Set the width and height as needed */
  height: 800px;
  margin: auto;
}

/* Apply a linear gradient as the background */
.transferImagesContainer::before {
  content: ''; /* Create an empty pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); /* Adjust gradient colors and direction */
}
/* Style the Image component if needed */
.transferHeroImage{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust image sizing as needed */
}
.transferContainer{
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -50%);
  width: 80%;
  padding: 20px;
  /* margin-top: 50px; */
}



  /* @media (max-width: 960px) {
   .transferContainer{
    width: 100%;
   }
  } */
  .popContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 99999;
    background-color: black;
    /* background-color: skyblue; */
    text-align: center;
   padding: 20px;
   max-width: 250px;
    width: max-content;
    box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544);
  }
  .popText{
    color: white;
    font-weight: bold;
    
  }
  .innerPopContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .popButton{
    background-color: rgb(58, 135, 224);
    /* background-color: rgb(58, 135, 224); */
    padding:7px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
  }
  
  .popClose{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }.popContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 99999;
    background-color: black;
    /* background-color: skyblue; */
    text-align: center;
   padding: 20px;
   max-width: 250px;
    width: max-content;
    box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544);
  }
  .popText{
    color: white;
    font-weight: bold;
    
  }
  .innerPopContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .popButton{
    background-color: rgb(58, 135, 224);
    /* background-color: rgb(58, 135, 224); */
    padding:7px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
  }
  
  .popClose{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }