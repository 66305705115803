.imageContainer {
    position: relative; /* Ensure the container can contain absolute-positioned elements */
    width: 100%; /* Set the width and height as needed */
    height: 800px;
   
  }
  
  /* Apply a linear gradient as the background */
  .imageContainer::before {
    content: ''; /* Create an empty pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); /* Adjust gradient colors and direction */
  }
  
  /* Style the Image component if needed */
  .heroImage{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adjust image sizing as needed */
  }
  /* .text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  } */

  .text p{
    font-size: 1.4rem;
    color: black;
    font-weight: 800;
    text-align: center;
   
  }
  .homeButton{
    padding: 10px 20px;
    background-color: white;
    color: rgb(58, 135, 224);
    font-weight: bold;
    border-radius: 10px;
    align-self: center;
    margin:10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homeButton:hover{
    border: 2px solid rgb(58, 135, 224);
    background-color: transparent;
  }
  .heroRobot{
    position: absolute;
    top: 10px;
    right: 20px;
    animation: Shake 0.5s linear infinite; 
  }

  @keyframes Shake { 
    0% { 
        transform: rotate(3deg); 
    } 
  
    25% { 
        transform: rotate(-3deg); 
    } 
  
    50% { 
        transform: rotate(3deg); 
    } 
  
    75% { 
        transform: rotate(-3deg); 
    } 
  
    100% { 
        transform: rotate(3deg); 
    } 
}

/* .emailText{
    font-weight: 600;
    font-size: 20px;
} */
.loginButton{
    background-color: rgb(58, 135, 224);
    padding: 10px;
    color: white;
    font-weight: 600;
    width: 100%;
}

/* DEMO-SPECIFIC STYLES */
.emailText {
  color: gray;
  font-weight: 600;
  font-size: 13px;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid gray; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .17em; /* Adjust as needed */
  animation: 
    typing 2.0s steps(15, end),
    blink-caret .5s step-end infinite;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.inputContainer{
  position: relative;
}
.emailInput{
min-width: 250px;
}
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  .modal{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.766);
    /* background-color: rgb(0, 0, 0); */
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 150px;
    z-index: 9999999;
  }
  .close{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    cursor: pointer;
  }
  .contactInnerContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding-top: 150px;
    /* backdrop-filter: blur(10px);
    opacity: 0.8; */
    
  }

  .loginImage{
    width: 300px;
    height: 300px;
    object-fit: contain;
    animation: Shake 0.5s linear infinite; 
  }
.chatContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  
}
.btn {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width:220.52px;
  min-height: 56px;
  font-family: calibri;
  font-size: 1.2em;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: 2px solid rgb(58, 135, 224);
  color: white;
  background-color : transparent;
  overflow: hidden;
  margin:10px auto;
}

i {
  font-size: 1em;
  padding: 0 10px;
  transition: all 0.5s linear;
}

  .btn:hover i {
      transform: translateX(10px);
  }

  .btn::before, .btn::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background-color: rgb(58, 135, 224);
  z-index: -1;
  transition: all 400ms ease-in;
  }

  .btn:hover {
      color: #fff;
  }

  .btn::before {
      opacity: 0.3;
  }

  .btn::after {
      transition-delay: 0.3s;
  }

  .btn:hover::before, .btn:hover::after {
      top: 0;
  }

  .popContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 99999;
    background-color: black;
    /* background-color: skyblue; */
    text-align: center;
   padding: 20px;
   max-width: 250px;
    width: max-content;
    box-shadow: 2px 5px 20px 5px rgba(173, 216, 230, 0.544);
  }
  .popText{
    color: white;
    font-weight: bold;
    
  }
  .innerPopContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .popButton{
    background-color: rgb(58, 135, 224);
    /* background-color: rgb(58, 135, 224); */
    padding:7px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
  }
  
  .popClose{
    color: rgb(58, 135, 224);
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }


@media screen and (max-width:960px) {
  .chatContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
  }
}