.datatable{
    height: 600px;
    padding: 20px;
    background-color: white;
    width: 70%;
    margin: auto;
    padding-bottom: 50px;
    z-index: 1;

    /* .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.active{
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
        }
        &.pending{
            background-color: rgba(255, 217, 0, 0.05);
            color: goldenrod
        }
        &.passive{
            background-color: rgba(255, 0, 0,0.05);
            color: crimson;
        }

        
    } */


}

.datatableTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;

}

.link{
    text-decoration: none;
    color: green;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.cellWithImg{
    display: flex;
    align-items: center;

   
}
.cellImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}
.cellAction{
    display: flex;
    align-items: center;
    gap: 20px;
}
.viewButton{
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
}
.deleteButton{
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
}
.datagrid{
    border-right: 1px solid black;
    
}