.portfolioRatesContainer{
    width:80%;
     margin: auto;
}
.loginButton{
    background-color: rgb(58, 135, 224);
    padding: 10px;
    color: white;
    font-weight: 600;
    width: 100%;
}

.backButton{
  font-size: 30px;
  background-color: rgba(14, 109, 197, 0.274) ;
  color:rgb(14, 93, 197) ;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-top: 50px;
  margin-left: 70px;
  margin-bottom: 20px;
}
.backButton:hover{
  background-color: rgb(14, 93, 197);
  color: white;
  cursor: pointer;
}

/* DEMO-SPECIFIC STYLES */
.emailText {
    color: black;
    font-weight: 600;
    font-size: 20px;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }

  .emailTextTwo {
    color: black;
    font-weight: 600;
    font-size: 20px;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(30, end) 1s,
      blink-caret .5s step-end infinite;
  }
  
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  .loginImage{
    animation: Shake 0.5s linear infinite; 
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  @keyframes Shake { 
    0% { 
        transform: rotate(3deg); 
    } 
  
    25% { 
        transform: rotate(-3deg); 
    } 
  
    50% { 
        transform: rotate(3deg); 
    } 
  
    75% { 
        transform: rotate(-3deg); 
    } 
  
    100% { 
        transform: rotate(3deg); 
    } 
}


  @media (max-width: 960px) {
   .transferContainer{
    width: 100%;
   }
  }