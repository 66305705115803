.imageContainer {
    position: relative; /* Ensure the container can contain absolute-positioned elements */
    width: 100%; /* Set the width and height as needed */
    height: 700px;
  }
  
  /* Apply a linear gradient as the background */
  .imageContainer::before {
    content: ''; /* Create an empty pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); /* Adjust gradient colors and direction */
  }
  
  /* Style the Image component if needed */
  .heroImage{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adjust image sizing as needed */
  }
  .text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding-top: 100px;
  }
  .text h1{
    font-size: 3rem;
    color: rgb(58, 135, 224);
    font-weight: 800;
    text-align: center;
    
  }
  .text p{
    font-size: 1.4rem;
    color: white;
    font-weight: 800;
    text-align: center;
   
  }
  .homeButton{
    padding: 10px 20px;
    background-color: white;
    color: rgb(58, 135, 224);
    font-weight: bold;
    border-radius: 10px;
    align-self: center;
    margin:10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homeButton:hover{
    border: 2px solid rgb(58, 135, 224);
    background-color: transparent;
  }
  .heroRobot{
    position: absolute;
    top: 100px;
    right: 20px;
    animation: Shake 0.5s linear infinite; 
  }

  @keyframes Shake { 
    0% { 
        transform: rotate(3deg); 
    } 
  
    25% { 
        transform: rotate(-3deg); 
    } 
  
    50% { 
        transform: rotate(3deg); 
    } 
  
    75% { 
        transform: rotate(-3deg); 
    } 
  
    100% { 
        transform: rotate(3deg); 
    } 
}
.btn {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width:220.52px;
  min-height: 56px;
  font-family: calibri;
  font-size: 1.2em;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: 2px solid rgb(58, 135, 224);
  color: white;
  background-color : transparent;
  overflow: hidden;
  margin:10px auto;
}

i {
  font-size: 1em;
  padding: 0 10px;
  transition: all 0.5s linear;
}

  .btn:hover i {
      transform: translateX(10px);
  }

  .btn::before, .btn::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background-color: rgb(58, 135, 224);
  z-index: -1;
  transition: all 400ms ease-in;
  }

  .btn:hover {
      color: #fff;
  }

  .btn::before {
      opacity: 0.3;
  }

  .btn::after {
      transition-delay: 0.3s;
  }

  .btn:hover::before, .btn:hover::after {
      top: 0;
  }
