.innerContainer{
    width: 100%;
    /* margin: 50px; */
    padding: 20px;
    display: flex;
    gap: 20px;

}
.innerLeft{
    /* 'flex-1 flex gap-10 p-10 w-full justify-center flex-wrap' */
    display: flex;
    gap:10px;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    height: max-content;
}

.btn {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width:220.52px;
    min-height: 56px;
    font-family: calibri;
    font-size: 1.2em;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    border: 2px solid rgb(58, 135, 224);
    color: rgb(58, 135, 224);
    background-color : transparent;
    overflow: hidden;
  }
  
  i {
    font-size: 1em;
    padding: 0 10px;
    transition: all 0.5s linear;
  }
  
    .btn:hover i {
        transform: translateX(10px);
    }
  
    .btn::before, .btn::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: rgb(58, 135, 224);
    z-index: -1;
    transition: all 400ms ease-in;
    }
  
    .btn:hover {
        color: #fff;
    }
  
    .btn::before {
        opacity: 0.3;
    }
  
    .btn::after {
        transition-delay: 0.3s;
    }
  
    .btn:hover::before, .btn:hover::after {
        top: 0;
    }
  

@media screen  and (max-width:960px) {
    .innerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .innerRight{
        width: 100%;
        height: 600px;
       }
       /* .innerLeft{
        display: flex;
        flex-direction: column;
       } */
  }
  