.forexContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    /* justify-content: center; */
    padding: 20px;
}
.newsContainer{
    margin-top: 100px;
    margin-bottom: 100px;
    overflow: hidden;
}

@media screen and (max-width:1100px) {
    .forexContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
    }
}