.navEmail{
    font-size: 18px;
}

@media screen and (max-width: 960px) {
    .navEmail{
        font-size: 10px;
    }
}

@media screen and (max-width: 280px) {
    .navEmail{
        width: 50px;
        height: max-content;
        font-size: 7px;
        word-wrap: break-word;
    }
}